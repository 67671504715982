import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import {
	Container,
	Grid,
	Box,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Divider } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hotelInfoDispatch } from '../reducers/HotelReducer';

const HotelReview = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { hotelInfo, roomInfo } = useSelector((state) => state.hoteltbo);
	console.log('location', location);
	useEffect(() => {
		window.scrollTo(0, 0);
		let form = {
			resultIndex: location.state.item.ResultIndex,
			hotelcode: location.state.item.HotelCode,
			traceId: location.state.traceId,
		};
		dispatch(hotelInfoDispatch(form));
	}, []);
	return (
		<>
			<Navbar />
			<MNavbar />
			<Container style={{ marginTop: matches ? 0 : 100 }}>
				<Grid container spacing={2}>
					{/* {hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Images.map((i) => {
						return;
					})} */}
					<Grid item xs={12} lg={6}>
						<img
							src={hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Images[0]}
							style={{ borderRadius: 5, width: '100%', height: 315 }}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid container spacing={2}>
							{hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Images.map(
								(i, index) => {
									if (index < 4) {
										return (
											<Grid item xs={12} lg={6}>
												<img
													src={i}
													style={{
														borderRadius: 5,
														width: '100%',
														height: 150,
													}}
												/>
											</Grid>
										);
									}
								}
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid container style={{ marginTop: 10 }} spacing={4}>
					<Grid item xs={12} lg={8}>
						<h1 style={{ fontSize: 26, fontWeight: 'bold' }}>
							{hotelInfo?.result?.HotelInfoResult?.HotelDetails?.HotelName}
						</h1>
						<Box
							mt={2}
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Box display={'flex'} alignItems={'center'}>
								<Box
									style={{
										background: 'rgba(226, 246, 255, 1)',
										padding: 6,
										fontSize: 12,
										borderRadius: 5,
									}}>
									{/* 8.1 */}
								</Box>
								<Box ml={1}>
									{/* <span style={{ fontSize: 10 }}>Excellent</span> */}
									{/* <p style={{ fontSize: 10 }}>190 Rating</p> */}
								</Box>
							</Box>
							<Box style={{ fontSize: 12 }}>
								{hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Address}
							</Box>
						</Box>
						<Box
							mt={3}
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Box
								style={{
									borderBottom: '4px solid rgba(243, 106, 0, 1)',
									borderRadius: 3,
									color: 'rgba(243, 106, 0, 1)',
								}}>
								Overview
							</Box>
							<Box>Rooms</Box>
							<Box>Location</Box>
							<Box>Reviews</Box>
							<Box>Facilities</Box>
							<Box>Policies</Box>
						</Box>
						<Divider style={{ border: '1px solid rgba(194, 199, 208, 1)' }} />
						<h1 style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
							Facilities
						</h1>
						{/* <Box mt={2} display={'flex'} justifyContent={'space-between'}> */}
						{/* <Grid container spacing={2} style={{ marginTop: 20 }}> */}
						{hotelInfo?.result?.HotelInfoResult?.HotelDetails?.HotelFacilities?.map(
							(i, index) => {
								return index < 10 ? (
									<>
										<li>{i}</li>
									</>
								) : (
									''
								);
							}
						)}
						{/* </Grid> */}
						<Divider style={{ border: '1px solid rgba(194, 199, 208, 1)' }} />
						<h1 style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>
							Attraction
						</h1>
						{/* <Box mt={2} display={'flex'} justifyContent={'space-between'}> */}
						{hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Attractions?.map(
							(i) => {
								return <li>{i.Value}</li>;
							}
						)}
						<Divider style={{ border: '1px solid rgba(194, 199, 208, 1)' }} />
						<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>Description</h1>
						{/* <Box mt={2} display={'flex'} justifyContent={'space-between'}> */}
						<div
							style={{ fontSize: 14, marginTop: 20 }}
							dangerouslySetInnerHTML={{
								__html:
									hotelInfo?.result?.HotelInfoResult?.HotelDetails?.Description,
							}}
						/>
						<Divider style={{ border: '1px solid rgba(194, 199, 208, 1)' }} />
						<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>Policy</h1>
						{/* <Box mt={2} display={'flex'} justifyContent={'space-between'}> */}
						<div
							style={{ fontSize: 14, marginTop: 20 }}
							dangerouslySetInnerHTML={{
								__html:
									hotelInfo?.result?.HotelInfoResult?.HotelDetails?.HotelPolicy,
							}}
						/>

						{/* <div
							style={{ fontSize: 14, marginTop: 20 }}
							dangerouslySetInnerHTML={{
								__html:
									hotelInfo?.result?.HotelInfoResult?.HotelDetails?.HotelPolicy,
							}}
						/> */}

						{/* </Box> */}

						<table style={{ width: '100%', marginTop: 50 }} id='room'>
							<tr>
								<th
									style={{
										// border: '1px solid rgba(217, 217, 217, 1)',
										padding: 20,
										width: '30%',
										fontSize: 12,
									}}>
									Room Type
								</th>
								<th
									style={{
										// border: '1px solid rgba(217, 217, 217, 1)',
										padding: 20,
										width: '50%',
										fontSize: 12,
									}}>
									Benefits
								</th>
								<th
									style={{
										// border: '1px solid rgba(217, 217, 217, 1)',
										padding: 20,
										width: '20%',
										fontSize: 12,
									}}>
									Price
								</th>
							</tr>
							{roomInfo?.result?.GetHotelRoomResult?.HotelRoomsDetails?.length >
								0 &&
								roomInfo?.result?.GetHotelRoomResult?.HotelRoomsDetails.map(
									(d) => {
										return (
											<>
												<tr>
													<th rowspan='2' style={{ padding: 20 }}>
														<h2
															style={{
																color: '#088383',
																fontWeight: 'bold',
															}}>
															{d.RoomTypeName}
														</h2>
														<img
															src={
																hotelInfo?.result?.HotelInfoResult?.HotelDetails
																	?.Images[0]
															}
															style={{ marginTop: 20 }}
														/>
														<ul style={{ marginTop: 20 }}>
															<li style={{ fontSize: 12 }}>
																SmokingPreference : {d.SmokingPreference}
															</li>
															{/* <li style={{ marginTop: 10, fontSize: 12 }}>
																Bed Type : Queen Bed
															</li>
															<li style={{ marginTop: 10, fontSize: 12 }}>
																Bed Type : Queen Bed
															</li> */}
														</ul>
													</th>
													<td style={{ padding: 10 }}>
														{d.Inclusion.map((i) => i)}
													</td>
													<td style={{ textAlign: 'center' }}>
														<h1 style={{ fontSize: 14 }}>
															₹
															{Number(
																Math.round(
																	Number(d.Total_Amount) -
																		Number(d.MFB_Discount)
																)
															)}
														</h1>
														<p style={{ fontSize: 10 }}></p>
														<Button
															onClick={() =>
																navigate('/hotelconfirm', {
																	state: {
																		item: location.state.item,
																		selectedRoom: d,
																		traceId: location.state.traceId,
																	},
																})
															}
															style={{
																background: '#088383',
																color: 'white',
																textTransform: 'capitalize',
																marginTop: 10,
																fontSize: 10,
															}}>
															Select Room
														</Button>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 10 }}>
														{/* {d.Amenities.map((i) => i)} */}
														<span>CancellationPolicy:-</span>
														<div
															style={{ fontSize: 14, marginTop: 20 }}
															dangerouslySetInnerHTML={{
																__html: d.CancellationPolicy,
															}}
														/>
													</td>
													<td style={{ textAlign: 'center' }}>
														{/* <h1 style={{ fontSize: 14 }}>₹1000</h1> */}
														{/* <p style={{ fontSize: 10 }}>per night</p>
														<Button
															style={{
																background: '#088383',
																color: 'white',
																textTransform: 'capitalize',
																marginTop: 10,
																fontSize: 10,
															}}>
															Select Room
														</Button> */}
													</td>
												</tr>
											</>
										);
									}
								)}
						</table>
						<Box display={'flex'} justifyContent={'space-between'} mt={3}>
							<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>Locations</h1>
							<span style={{ color: 'rgba(243, 106, 0, 1)', fontSize: 12 }}>
								View on google maps
							</span>
						</Box>

						<iframe
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.8680880436714!2d76.81703367503376!3d30.637674990221413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feb22049b88d1%3A0x2bc44d386904b2bf!2sMARINERS%20FOREX%20I%20Tour%20%26%20Forex%20Company!5e0!3m2!1sen!2sin!4v1714559879934!5m2!1sen!2sin'
							height='300'
							style={{ border: 0, width: '100%', marginTop: 20 }}
							allowfullscreen=''
							loading='lazy'></iframe>
						<Box display={'flex'} mt={3}>
							<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>
								Rating & Review
							</h1>
						</Box>
						<br />
						{/* <h1 style={{ fontWeight: 'bold', fontSize: 30 }}>4.2</h1>
						<Divider />
						<Box display={'flex'}>
							<img src={ellipse} />
							<p
								style={{
									fontSize: 12,
									marginLeft: 5,
									color: 'rgba(0, 117, 183, 1)',
									fontWeight: 'bold',
								}}>
								5.0 Amazing | Omar Siphron
							</p>
						</Box>
						<p style={{ fontSize: 12, marginLeft: 40 }}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
						<Divider />
						<Box display={'flex'}>
							<img src={ellipse} />
							<p
								style={{
									fontSize: 12,
									marginLeft: 5,
									color: 'rgba(0, 117, 183, 1)',
									fontWeight: 'bold',
								}}>
								5.0 Amazing | Omar Siphron
							</p>
						</Box>
						<p style={{ fontSize: 12, marginLeft: 40 }}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>
						<Divider />
						<Box display={'flex'}>
							<img src={ellipse} />
							<p
								style={{
									fontSize: 12,
									marginLeft: 5,
									color: 'rgba(0, 117, 183, 1)',
									fontWeight: 'bold',
								}}>
								5.0 Amazing | Omar Siphron
							</p>
						</Box>
						<p style={{ fontSize: 12, marginLeft: 40 }}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p> */}
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							style={{
								border: '1px solid rgba(217, 217, 217, 1)',
								padding: 20,
								borderRadius: 15,
							}}>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								// alignItems={'center'}
							>
								<img
									src={location.state.item.HotelPicture}
									style={{ width: 100, height: 100 }}
								/>
								<Box ml={3}>
									<Box
										style={{
											border: '1px solid rgba(0, 102, 146, 1)',
											// height: '20%',
											borderRadius: 10,
											fontSize: 12,
											padding: 5,
											textAlign: 'center',
											color: 'rgba(0, 102, 146, 1)',
										}}>
										Recommended Deal
									</Box>
									{/* <br></br> */}
									<h2
										style={{ fontSize: 20, fontWeight: 'bold', marginTop: 10 }}>
										{location.state.item.HotelName}
									</h2>
								</Box>
							</Box>
							<Box mt={1}>
								{/* <li style={{ color: 'rgba(222, 53, 56, 1)', fontSize: 12 }}>
									Cancellation charge apply
								</li>
								<li style={{ color: 'rgba(222, 53, 56, 1)', fontSize: 12 }}>
									Free WiFi
								</li> */}
								<br />
								{/* <span style={{ fontWeight: 'bold' }}>
									₹ {location.state.item.Total_Amount}{' '}
								</span> */}
								<span style={{ fontSize: 12, color: 'rgba(63, 63, 63, 1)' }}>
									{/* + ₹ 5,449 taxes & fees */}
								</span>
								<p style={{ fontSize: 12, color: 'rgba(63, 63, 63, 1)' }}>
									{/* per night for 1 room */}
								</p>
								<Button
									onClick={() => navigate('/hotelconfirm')}
									style={{
										background: 'rgba(243, 106, 0, 1)',
										width: '100%',
										marginTop: 10,
										color: 'rgba(251, 251, 251, 1)',
										textTransform: 'capitalize',

										padding: 10,
										borderRadius: 5,
									}}>
									Recommended Deal
								</Button>
								{/* <Button
									style={{
										background: 'rgba(255, 235, 219, 1)',
										width: '100%',
										marginTop: 10,
										color: 'rgba(243, 106, 0, 1)',
										textTransform: 'capitalize',
										
										padding: 10,
										borderRadius: 5,
									}}>
									View All Rooms
								</Button> */}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<MNavbar />
			<Footer />
		</>
	);
};

export default HotelReview;
