import React from 'react';
import { Container, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import arrowGreen from '../../assets/images/arrowGreen.svg';
import { useSelector } from 'react-redux';

const TopDest = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { tourdest } = useSelector((state) => state.home);
	return (
		<>
			<div style={{ marginTop: 100 }}>
				{' '}
				<p
					style={{
						textAlign: 'center',
						color: '#088383',
						// fontSize: matches ? 30 : '',
					}}>
					Explore the world
				</p>
				<Container style={{ marginTop: 20 }}>
					<Grid container>
						<Grid item xs={3}></Grid>
						<Grid item xs={6}>
							<h1
								style={{
									textAlign: 'center',
									color: 'rgba(8, 30, 42, 1)',
									fontSize: matches ? 38 : 48,
									fontWeight: 'bold',
								}}>
								We provide top tourist destinations
							</h1>
						</Grid>
						<Grid item xs={3}></Grid>
					</Grid>
				</Container>
			</div>
			<Container style={{ marginTop: 20 }}>
				<Grid container spacing={2}>
					{tourdest?.result?.map((i, index) => {
						return (
							<Grid item xs={12} md={6} lg={4} style={{ marginTop: 30 }}>
								<Box>
									<Box>
										<img src={i.imgpath} style={{ height: 250 }} />
										<div
											style={{
												position: 'absolute',
												marginTop: '-230px',
												marginLeft: '296px',
												background: '#F7921E',
												color: 'white',
												padding: '5px 10px',
												fontSize: 12,
												borderRadius: 2,
											}}>
											{i.tour} TOURS
										</div>
										<Box
											style={{
												boxShadow: '0px 4px 21px 0px #0000000F',

												border: '1px solid grey',
												position: 'relative',
												// width: '21%',
												marginLeft: '20px',
												marginRight: '20px',
												marginTop: '-30px',
												zIndex: 100,
												background: 'white',
												border: 'none',
												borderRadius: 5,
											}}>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												style={{
													backgroundImage:
														'-webkit-linear-gradient(-33deg, #088383 50%, rgba(255, 255, 255, 1) 50%)',
													padding: 10,
													borderRadius: 5,
												}}>
												<p style={{ color: 'white' }}>{i.title}</p>
												<Box></Box>
												<Box></Box>
											</Box>

											<Box
												style={{ padding: 10 }}
												display={'flex'}
												justifyContent={'space-between'}
												alignItems={'center'}>
												<p style={{ fontSize: 14 }}>View all tours</p>
												<div
													style={{
														background: '#F4F8F6',
														alignItems: 'center',
														borderRadius: 25,
														height: '50px',
														width: '50px',
													}}>
													<img
														src={arrowGreen}
														style={{
															height: '8px',
															position: 'absolute',
															marginTop: '22px',
															marginLeft: '15px',
														}}
													/>
												</div>
											</Box>
										</Box>
									</Box>
								</Box>
							</Grid>
						);
					})}
					<Grid item xs={12} md={6} lg={4}></Grid>
					<Grid item xs={12} md={6} lg={4}></Grid>
				</Grid>
			</Container>
		</>
	);
};

export default TopDest;
