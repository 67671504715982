import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Line from '../../assets/images/Line.svg';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../assets/images/cross.svg';
import Skeleton from '@mui/material/Skeleton';
import { TBOFlightFareRuleDispatch } from '../../reducers/TBOReducer';

const FlightRowInternational = ({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [data, setData] = useState('');
	const [toggle, setToggle] = useState('one');
	const handleToggle = (val) => {
		setToggle(val);
	};
	// const { loading } = useSelector((state) => state.home);
	const { tbo, orderId, fareRule, loading, loadingRule } = useSelector(
		(state) => state.tbo
	);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const handleData = (val) => {
		if (data === val) {
			setData('');
		} else {
			setData(val);
		}
	};
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	// console.log('trace', tbo?.result?.Response.TraceId);
	return (
		<>
			<Box
				mt={2}
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
				}}>
				<Box
					display={'flex'}
					style={{ padding: 25 }}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Box>
						<Box display={'flex'}>
							<Box display={'flex'} alignItems={'center'}>
								<img
									src={`https://nitish.musafirbazar.com/static/media/${
										item.Supplier === 'TBO' ? item.AirlineCode : ''
									}.gif`}
									style={{ borderRadius: 5, height: 45 }}
								/>
								<Box ml={2}>
									<p
										title={
											item.Supplier === 'TBO'
												? item.Segments[0].slice(0, 1).map((i) => {
														return i.Airline.AirlineName;
												  })
												: ''
										}
										style={{
											fontWeight: 'bold',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '95px',
											height: '1.2em',
											whiteSpace: 'nowrap',
										}}>
										{item.Supplier === 'TBO'
											? item.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.AirlineName;
											  })
											: ''}
									</p>
									<p style={{ fontSize: 12 }}>
										{`${
											item.Supplier === 'TBO'
												? item.Segments[0].slice(0, 1).map((i) => {
														return i.Airline.AirlineCode;
												  })
												: ''
										}-${
											item.Supplier === 'TBO'
												? item.Segments[0].slice(0, 1).map((i) => {
														return i.Airline.FlightNumber;
												  })
												: ''
										}`}
									</p>
									<p
										title={item?.FareClassification?.Type}
										style={{
											background: item?.FareClassification?.Color,
											textAlign: 'center',
											borderRadius: 2,
											marginTop: 5,
											fontSize: 10,

											fontWeight: 'bold',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '50px',
											// height: '1.2em',
											whiteSpace: 'nowrap',
											color:
												item?.FareClassification?.Type === 'Tactical'
													? 'white'
													: item?.FareClassification?.Type === 'Saver'
													? 'White'
													: 'black',
										}}>
										{item?.FareClassification?.Type}
									</p>
								</Box>
							</Box>
							{matches ? (
								''
							) : (
								<>
									<Box ml={5}>
										<p
											style={{
												fontWeight: 'bold',
											}}>
											{item.Supplier
												? item.Segments[0].slice(0, 1).map((i) => {
														return i.Origin.DepTime.substr(11, 5);
												  })
												: ''}
										</p>
										<p style={{ fontFamily: 'Outfit' }}>
											{item.Supplier === 'TBO' ? item.FareRules[0].Origin : ''}
										</p>
									</Box>
									<Box textAlign={'center'}>
										<p style={{ fontSize: 14 }}>
											{timeConvert(
												item.Supplier === 'TBO'
													? item.Segments[0].length == 2
														? timedifference(
																item.Segments[0]
																	.filter((i, index) => index === 0)
																	.map((it) => {
																		return it.Origin.DepTime;
																	}),
																item.Segments[0]
																	.filter((i, index) => index === 1)
																	.map((it) => {
																		return it.Destination.ArrTime;
																	})
														  )
														: item.Segments[0].length == 1
														? item.Segments[0].map((i) =>
																timedifference(
																	item.Segments[0]
																		.filter((i, index) => index === 0)
																		.map((it) => {
																			return it.Origin.DepTime;
																		}),
																	item.Segments[0]
																		.filter((i, index) => index === 0)
																		.map((it) => {
																			return it.Destination.ArrTime;
																		})
																)
														  )
														: item.Segments[0].length == 3
														? timedifference(
																item.Segments[0]
																	.filter((i, index) => index === 0)
																	.map((it) => {
																		return it.Origin.DepTime;
																	}),
																item.Segments[0]
																	.filter((i, index) => index === 2)
																	.map((it) => {
																		return it.Destination.ArrTime;
																	})
														  )
														: ''
													: ''
											)}
										</p>
										<img
											src={Line}
											style={{ marginLeft: 10, marginRight: 10 }}
										/>
										<p style={{ fontSize: 14 }}>
											{item.Supplier === 'TBO'
												? item.Segments[0].length === 1
													? 'Non-Stop'
													: item.Segments[0].length === 2
													? '1 Stop'
													: item.Segments[0].length === 3
													? '2 Stop'
													: ''
												: ''}
										</p>
									</Box>
									<Box>
										<p
											style={{
												fontWeight: 'bold',
											}}>
											{item.Supplier === 'TBO'
												? item.Segments[0].length == 3
													? item.Segments[0]
															.filter((i, index) => index === 2)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: item.Segments[0].length == 2
													? item.Segments[0]
															.filter((i, index) => index === 1)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: item.Segments[0].length == 1
													? item.Segments[0]
															.filter((i, index) => index === 0)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: ''
												: ''}
										</p>
										<p style={{ fontFamily: 'Outfit' }}>
											{item.Supplier === 'TBO'
												? item.Segments[0].length == 2
													? item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.AirportCode;
															})
													: item.Segments[0].length == 3
													? item.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.AirportCode;
															})
													: item.Segments[0].length == 1
													? item.Segments[0].map((it) => {
															return it.Destination.Airport.AirportCode;
													  })
													: ''
												: ''}
										</p>
									</Box>
								</>
							)}
						</Box>
						<Box display={'flex'} mt={2}>
							<Box display={'flex'} alignItems={'center'}>
								<img
									src={`https://nitish.musafirbazar.com/static/media/${
										item.Supplier === 'TBO'
											? item?.Segments[1]?.slice(0, 1)?.map((i) => {
													return i.Airline.AirlineCode;
											  })
											: ''
									}.gif`}
									style={{ borderRadius: 5, height: 45 }}
								/>
								<Box ml={2}>
									<p
										title={
											item.Supplier === 'TBO'
												? item?.Segments[1]?.slice(0, 1)?.map((i) => {
														return i.Airline.AirlineName;
												  })
												: ''
										}
										style={{
											fontWeight: 'bold',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '95px',
											height: '1.2em',
											whiteSpace: 'nowrap',
										}}>
										{item?.Supplier === 'TBO'
											? item?.Segments[1]?.slice(0, 1)?.map((i) => {
													return i.Airline.AirlineName;
											  })
											: ''}
									</p>
									<p style={{ fontSize: 12 }}>
										{`${
											item?.Supplier === 'TBO'
												? item?.Segments[1]?.slice(0, 1).map((i) => {
														return i.Airline.AirlineCode;
												  })
												: ''
										}-${
											item?.Supplier === 'TBO'
												? item?.Segments[1]?.slice(0, 1).map((i) => {
														return i.Airline.FlightNumber;
												  })
												: ''
										}`}
									</p>
									<p
										title={item?.FareClassification?.Type}
										style={{
											background: item?.FareClassification?.Color,
											textAlign: 'center',
											borderRadius: 2,
											marginTop: 5,
											fontSize: 10,

											fontWeight: 'bold',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '50px',
											// height: '1.2em',
											whiteSpace: 'nowrap',
											color:
												item?.FareClassification?.Type === 'Tactical'
													? 'white'
													: item?.FareClassification?.Type === 'Saver'
													? 'White'
													: 'black',
										}}>
										{item?.FareClassification?.Type}
									</p>
								</Box>
							</Box>
							{matches ? (
								''
							) : (
								<>
									<Box ml={5}>
										<p
											style={{
												fontWeight: 'bold',
											}}>
											{item?.Supplier === 'TBO'
												? item?.Segments[1]?.slice(0, 1)?.map((i) => {
														return i.Origin.DepTime.substr(11, 5);
												  })
												: ''}
										</p>
										<p style={{ fontFamily: 'Outfit' }}>
											{item?.Supplier === 'TBO'
												? item?.Segments[1]?.slice(0, 1)?.map((i) => {
														return i.Origin.Airport.AirportCode;
												  })
												: ''}
										</p>
									</Box>
									<Box textAlign={'center'}>
										<p style={{ fontSize: 14 }}>
											{timeConvert(
												item?.Supplier === 'TBO'
													? item?.Segments[1]?.length == 2
														? timedifference(
																item?.Segments[1]
																	.filter((i, index) => index === 0)
																	.map((it) => {
																		return it.Origin.DepTime;
																	}),
																item?.Segments[1]
																	.filter((i, index) => index === 1)
																	.map((it) => {
																		return it.Destination.ArrTime;
																	})
														  )
														: item?.Segments[1]?.length == 1
														? item?.Segments[1]?.map((i) =>
																timedifference(
																	item.Segments[1]
																		.filter((i, index) => index === 0)
																		.map((it) => {
																			return it.Origin.DepTime;
																		}),
																	item.Segments[1]
																		.filter((i, index) => index === 0)
																		.map((it) => {
																			return it.Destination.ArrTime;
																		})
																)
														  )
														: item?.Segments[1]?.length == 3
														? timedifference(
																item?.Segments[1]
																	.filter((i, index) => index === 0)
																	.map((it) => {
																		return it.Origin.DepTime;
																	}),
																item?.Segments[1]
																	.filter((i, index) => index === 2)
																	.map((it) => {
																		return it.Destination.ArrTime;
																	})
														  )
														: ''
													: ''
											)}
										</p>
										<img
											src={Line}
											style={{ marginLeft: 10, marginRight: 10 }}
										/>
										<p style={{ fontSize: 14 }}>
											{item?.Supplier === 'TBO'
												? item?.Segments[1]?.length === 1
													? 'Non-Stop'
													: item?.Segments[1]?.length === 2
													? '1 Stop'
													: item?.Segments[1]?.length === 3
													? '2 Stop'
													: ''
												: ''}
										</p>
									</Box>
									<Box>
										<p
											style={{
												fontWeight: 'bold',
											}}>
											{item?.Supplier === 'TBO'
												? item?.Segments[1]?.length == 3
													? item.Segments[1]
															.filter((i, index) => index === 2)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: item?.Segments[1]?.length == 2
													? item?.Segments[1]
															?.filter((i, index) => index === 1)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: item?.Segments[1]?.length == 1
													? item?.Segments[1]
															?.filter((i, index) => index === 0)
															.map((i) => {
																return i.Destination.ArrTime.substr(11, 5);
															})
													: ''
												: ''}
										</p>
										<p style={{ fontFamily: 'Outfit' }}>
											{item?.Supplier === 'TBO'
												? item?.Segments[1]?.length == 2
													? item?.Segments[1]
															?.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.AirportCode;
															})
													: item?.Segments[1]?.length == 3
													? item?.Segments[1]
															?.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.AirportCode;
															})
													: item?.Segments[1]?.length == 1
													? item?.Segments[1]?.map((it) => {
															return it.Destination.Airport.AirportCode;
													  })
													: ''
												: ''}
										</p>
									</Box>
								</>
							)}
						</Box>
					</Box>
					<Box>
						<p
							style={{
								fontWeight: 'bold',
								fontSize: 18,
							}}>
							₹ 
							{item?.Supplier === 'TBO'
								? Number(
										Math.round(
											Number(
												Number(Math.round(item.Fare.Total_Amount)) -
													Number(Math.round(item.Fare.MFB_Discount))
											)
										)
								  )
								: ''}
						</p>
						<p style={{ fontSize: 12 }}>
							{item?.Supplier === 'TBO'
								? item?.IsRefundable
									? 'Refundable'
									: 'Non-Refundable'
								: ''}
						</p>
					</Box>
					<button
						onClick={() =>
							navigate('/flightreview', {
								state: {
									item: item,
									formData: location.state,
									traceId: tbo?.result?.Response.TraceId,
									orderId: orderId.result,
								},
							})
						}
						style={{
							backgroundColor: '#088383',
							padding: '8px 30px',
							color: 'white',
							borderRadius: 5,
						}}>
						Book
					</button>
				</Box>
				{matches ? (
					<>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							style={{ padding: '0px 25px' }}>
							<Box>
								<p
									style={{
										fontWeight: 'bold',
									}}>
									{item.Supplier
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Origin.DepTime.substr(11, 5);
										  })
										: ''}
								</p>
								<p style={{ fontFamily: 'Outfit' }}>
									{item.Supplier === 'TBO' ? item.FareRules[0].Origin : ''}
								</p>
							</Box>
							<Box textAlign={'center'}>
								<p style={{ fontSize: 14 }}>
									{timeConvert(
										item.Supplier === 'TBO'
											? item.Segments[0].length == 2
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: item.Segments[0].length == 1
												? item.Segments[0].map((i) =>
														timedifference(
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Origin.DepTime;
																}),
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Destination.ArrTime;
																})
														)
												  )
												: item.Segments[0].length == 3
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: ''
											: ''
									)}
								</p>
								<img src={Line} />
								<p style={{ fontSize: 14 }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length === 1
											? 'Non-Stop'
											: item.Segments[0].length === 2
											? '1 Stop'
											: item.Segments[0].length === 3
											? '2 Stop'
											: ''
										: ''}
								</p>
							</Box>
							<Box>
								<p
									style={{
										fontWeight: 'bold',
									}}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 1
											? item.Segments[0]
													.filter((i, index) => index === 0)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: ''
										: ''}
								</p>
								<p style={{ fontFamily: 'Outfit' }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 1
											? item.Segments[0].map((it) => {
													return it.Destination.Airport.AirportCode;
											  })
											: ''
										: ''}
								</p>
							</Box>
						</Box>
					</>
				) : (
					''
				)}
				<Box
					onClick={() => {
						let form = {
							TraceId: tbo?.result?.Response.TraceId,
							ResultIndex: item.ResultIndex,
						};
						dispatch(TBOFlightFareRuleDispatch(form));
						handleData(item.ResultIndex);
					}}
					style={{
						backgroundColor: '#F8F8F8',
						padding: '10px 25px',
						cursor: 'pointer',
					}}>
					<p
						style={{
							color: '#006692',
							fontWeight: '500',

							fontSize: 13,
						}}>
						Flight Details
					</p>
				</Box>
				{data === item.ResultIndex ? (
					<>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							style={{ padding: 15 }}>
							<Box display={'flex'} alignItems={'center'}>
								<Box
									onClick={() => handleToggle('one')}
									style={{
										backgroundColor: toggle === 'one' ? '#088383' : '',
										padding: 10,
										borderRadius: 5,
										color: toggle === 'one' ? 'white' : '',

										fontSize: 14,
										cursor: 'pointer',
									}}>
									Flight Information Onward
								</Box>
								<Box
									onClick={() => handleToggle('two')}
									style={{
										backgroundColor: toggle === 'two' ? '#088383' : '',
										padding: 10,
										borderRadius: 5,
										color: toggle === 'two' ? 'white' : '',

										fontSize: 14,
										cursor: 'pointer',
									}}>
									Flight Information Return
								</Box>
								<Box
									onClick={() => handleToggle('three')}
									style={{
										fontSize: 14,
										marginLeft: 10,
										backgroundColor: toggle === 'three' ? '#088383' : '',
										color: toggle === 'three' ? 'white' : '',
										padding: 10,
										borderRadius: 5,
										cursor: 'pointer',
									}}>
									Fare Details and Rules
								</Box>
							</Box>
							<Box onClick={() => handleData('')} style={{ cursor: 'pointer' }}>
								<img src={cross} />
							</Box>
						</Box>
						{toggle === 'one' &&
							item.Segments[0].map((i) => {
								return (
									<Box
										style={{
											padding: 15,
										}}>
										<Box
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderTopLeftRadius: 5,
												borderTopRightRadius: 5,
											}}>
											<p>
												{`${i.Origin.Airport.CityName} to ${
													i.Destination.Airport.CityName
												}, ${i.Origin.DepTime.split('T')[0]}`}
											</p>
										</Box>
										<Box
											style={{
												borderLeft: '1.33px solid rgba(203, 203, 203, 1)',
												borderRight: '1.33px solid rgba(203, 203, 203, 1)',
												borderBottom: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderBottomLeftRadius: 5,
												borderBottomRightRadius: 5,
											}}>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={matches ? 'space-between' : ''}>
												<Box display={'flex'} alignItems={'center'}>
													<img
														src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
														style={{ borderRadius: 5, height: 45 }}
													/>
													<Box ml={2}>
														<p
															style={{
																fontWeight: 'bold',
															}}>
															{i.Airline.AirlineName}
														</p>
														<p style={{ fontSize: 12 }}>
															{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}
														</p>
													</Box>
												</Box>
												{matches ? (
													<Box display={'flex'}>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Check in
															</p>
															<p style={{ fontSize: 10 }}>{i.Baggage}</p>
														</Box>
														<Box ml={2}>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Cabin
															</p>
															<p style={{ fontSize: 10 }}>{i.CabinBaggage}</p>
														</Box>
													</Box>
												) : (
													''
												)}
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												mt={2}>
												<Box>
													<p
														style={{
															fontWeight: 'bold',
														}}>
														{i.Origin.DepTime.substr(11, 5)}
													</p>
													<p style={{ fontFamily: 'Outfit' }}>
														{i.Origin.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 12 }}>{i.Origin.DepTime}</p>
													<p style={{ fontSize: 12 }}>
														{`Terminal ${i.Origin.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Origin.Airport.CityName},{' '}
														{i.Origin.Airport.CountryName}
													</p>
												</Box>
												<Box textAlign={'center'}>
													<p style={{ fontSize: 14 }}>
														{timeConvert(
															timedifference(
																i.Origin.DepTime,
																i.Destination.ArrTime
															)
														)}
													</p>
													<img src={Line} />
													<p style={{ fontSize: 14 }}>Non-Stop</p>
												</Box>
												<Box style={{ textAlign: matches ? 'end' : '' }}>
													<p
														style={{
															fontWeight: 'bold',
														}}>
														{i.Destination.ArrTime.substr(11, 5)}
													</p>
													<p style={{ fontFamily: 'Outfit' }}>
														{i.Destination.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Destination.ArrTime}
													</p>
													<p style={{ fontSize: 12 }}>
														{`Terminal ${i.Destination.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Destination.Airport.CityName},{' '}
														{i.Destination.Airport.CountryName}
													</p>
												</Box>
												{matches ? (
													''
												) : (
													<>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Baggage
															</p>
															<p style={{ fontFamily: 'Outfit' }}>Adult</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Check in
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.Baggage}
															</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Cabin
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.CabinBaggage}
															</p>
														</Box>
													</>
												)}
											</Box>
										</Box>
									</Box>
								);
							})}
						{toggle === 'two' &&
							item.Segments[1].map((i) => {
								return (
									<Box
										style={{
											padding: 15,
										}}>
										<Box
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderTopLeftRadius: 5,
												borderTopRightRadius: 5,
											}}>
											<p>
												{`${i.Origin.Airport.CityName} to ${
													i.Destination.Airport.CityName
												}, ${i.Origin.DepTime.split('T')[0]}`}
											</p>
										</Box>
										<Box
											style={{
												borderLeft: '1.33px solid rgba(203, 203, 203, 1)',
												borderRight: '1.33px solid rgba(203, 203, 203, 1)',
												borderBottom: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderBottomLeftRadius: 5,
												borderBottomRightRadius: 5,
											}}>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={matches ? 'space-between' : ''}>
												<Box display={'flex'} alignItems={'center'}>
													<img
														src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
														style={{ borderRadius: 5, height: 45 }}
													/>
													<Box ml={2}>
														<p
															style={{
																fontWeight: 'bold',
															}}>
															{i.Airline.AirlineName}
														</p>
														<p style={{ fontSize: 12 }}>
															{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}
														</p>
													</Box>
												</Box>
												{matches ? (
													<Box display={'flex'}>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Check in
															</p>
															<p style={{ fontSize: 10 }}>{i.Baggage}</p>
														</Box>
														<Box ml={2}>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Cabin
															</p>
															<p style={{ fontSize: 10 }}>{i.CabinBaggage}</p>
														</Box>
													</Box>
												) : (
													''
												)}
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												mt={2}>
												<Box>
													<p
														style={{
															fontWeight: 'bold',
														}}>
														{i.Origin.DepTime.substr(11, 5)}
													</p>
													<p style={{ fontFamily: 'Outfit' }}>
														{i.Origin.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 12 }}>{i.Origin.DepTime}</p>
													<p style={{ fontSize: 12 }}>
														{`Terminal ${i.Origin.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Origin.Airport.CityName},{' '}
														{i.Origin.Airport.CountryName}
													</p>
												</Box>
												<Box textAlign={'center'}>
													<p style={{ fontSize: 14 }}>
														{timeConvert(
															timedifference(
																i.Origin.DepTime,
																i.Destination.ArrTime
															)
														)}
													</p>
													<img src={Line} />
													<p style={{ fontSize: 14 }}>Non-Stop</p>
												</Box>
												<Box style={{ textAlign: matches ? 'end' : '' }}>
													<p
														style={{
															fontWeight: 'bold',
														}}>
														{i.Destination.ArrTime.substr(11, 5)}
													</p>
													<p style={{ fontFamily: 'Outfit' }}>
														{i.Destination.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Destination.ArrTime}
													</p>
													<p style={{ fontSize: 12 }}>
														{`Terminal ${i.Destination.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 12 }}>
														{i.Destination.Airport.CityName},{' '}
														{i.Destination.Airport.CountryName}
													</p>
												</Box>
												{matches ? (
													''
												) : (
													<>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Baggage
															</p>
															<p style={{ fontFamily: 'Outfit' }}>Adult</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Check in
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.Baggage}
															</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																Cabin
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.CabinBaggage}
															</p>
														</Box>
													</>
												)}
											</Box>
										</Box>
									</Box>
								);
							})}
						{toggle === 'three' && (
							<>
								{loadingRule ? (
									<p style={{ textAlign: 'center', marginBottom: 10 }}>
										Please wait...
									</p>
								) : (
									<div
										style={{ fontSize: 14, marginTop: 20, padding: 20 }}
										dangerouslySetInnerHTML={{
											__html:
												fareRule?.result?.Response?.FareRules[0]
													?.FareRuleDetail,
										}}
									/>
								)}
							</>
						)}
					</>
				) : (
					''
				)}
			</Box>
		</>
	);
};

export default FlightRowInternational;
