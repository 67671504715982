import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import {
	addBusBookingDispatch,
	busBlockDispatch,
} from '../reducers/BusReducer';
import PaymentVerifyBus from '../components/resuableComponents/PaymentVerifyBus';
import PaymentEaseBuzzBus from '../components/resuableComponents/PaymentEaseBuzzBus';
import { setFormDataBus } from '../reducers/UiReducer';
const BusConfirmForm = ({ pay, setPay, pgCharge }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const date = new Date();
	const { farequote, farequoteReturn } = useSelector((state) => state.tbo);
	const { depart, arrival } = useSelector((state) => state.ui);
	const { agentInfo, walletagent } = useSelector((state) => state.agent);
	const getActualFormat = (given) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	let combine = [];
	if (location.state) {
		location.state.passengerData.adult.map((item, index) => {
			location.state.seat.map((i, ind) => {
				if (index === ind) {
					combine.push({
						...i,
						passengerName: item,
					});
				}
			});
		});
	}
	console.log('Combine', combine);
	const handleSubmit = () => {
		let formData = {
			orderId: location.state.orderId,
			paymentId: invoice,
			availableTripId: location.state.busDetail?.id,
			boardingPointId: location.state.boardingPoint.bpId,
			destination: location.state.formData.destinationid,
			inventoryItems: combine.map((item, index) => {
				return {
					fare: item.fare,
					ladiesSeat: item.ladiesSeat,
					passenger: {
						address: '',
						age: item.passengerName.age,
						email: location.state.passengerData.email,
						gender: item.passengerName.gender,
						idNumber: '',
						idType: '',
						mobile: location.state.passengerData.phone,
						name: `${item.passengerName.firstname} ${item.passengerName.lastname}`,
						primary: index === 0 ? 'true' : '',
						title: item.passengerName.title,
					},
					seatName: item.name,
				};
			}),
			source: location.state.formData.sourceid,
		};
		let addBusBooking = {
			orderId: location.state.orderId,
			supplier: location.state.busDetail.Supplier,
			paymentStatus: '0',
			userEmail: location.state.passengerData.email,
			userPhone: location.state.passengerData.phone,
			user_id: '',
			user_type: agentInfo?.status === 200 ? 'Agent' : 'Guest',
			departureCity: location?.state?.formData?.departure_city,
			arrivalCity: location?.state?.formData?.arrival_city,
			doj: location.state.formData.dateofjourney,
			dor: '',
			journeyType: 'OneWay',
			nop: location.state.seat.length,
			PGCharge: pay === 'ONLINE' ? Number(pgCharge) : 0,
			actualFare:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.actualFare))
					.reduce((prev, next) => prev + next),
			markup:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.markup))
					.reduce((prev, next) => prev + next),
			baseFare:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.baseFare))
					.reduce((prev, next) => prev + next),
			partnerMarkup:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.partnerMarkup))
					.reduce((prev, next) => prev + next),
			agentMarkup:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.agentMarkup))
					.reduce((prev, next) => prev + next),
			totalFare:
				location.state.seat &&
				location.state?.seat
					?.map((item) => parseInt(item.totalFare))
					.reduce((prev, next) => prev + next),
			payableAmount:
				pay === 'OFFLINE'
					? Number(
							location.state.seat &&
								location.state?.seat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  )
					: Number(
							location.state.seat &&
								location.state?.seat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  ) + Number(pgCharge),
			ticketCost:
				pay === 'OFFLINE'
					? Number(
							location.state.seat &&
								location.state?.seat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  )
					: Number(
							location.state.seat &&
								location.state?.seat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  ) + Number(pgCharge),
			totalEarn: '0',
			cashBack: '0.00',
			paymentMode: pay,
			couponDiscount: 0.0,
			couponDetails: '',
			selectedPax: location.state.passengerData.adult,
			ticketSent: 0,
			agent_id: agentInfo?.status === 200 ? agentInfo?.result.result.id : 0,
		};
		console.log('FormData', formData);
		console.log('addBusBooking', addBusBooking);
		dispatch(addBusBookingDispatch(addBusBooking));
		dispatch(setFormDataBus(formData));
	};
	const handleBook = () => {
		let formData = {
			orderId: location.state.orderId,
			paymentId: invoice,
			availableTripId: location.state.busDetail?.id,
			boardingPointId: location.state.boardingPoint.bpId,
			destination: location.state.formData.destinationid,
			inventoryItems: combine.map((item, index) => {
				return {
					fare: item.fare,
					ladiesSeat: item.ladiesSeat,
					passenger: {
						address: '',
						age: item.passengerName.age,
						email: location.state.passengerData.email,
						gender: item.passengerName.gender,
						idNumber: '',
						idType: '',
						mobile: location.state.passengerData.phone,
						name: `${item.passengerName.firstname} ${item.passengerName.lastname}`,
						primary: index === 0 ? 'true' : '',
						title: item.passengerName.title,
					},
					seatName: item.name,
				};
			}),
			source: location.state.formData.sourceid,
		};
		if (pay === 'OFFLINE') {
			dispatch(busBlockDispatch(formData, navigate));
		}
	};
	return (
		<>
			<div
				style={{
					background: 'rgba(237, 240, 233, 1)',
					padding: 12,
					borderTopLeftRadius: 5,
					borderTopRightRadius: 5,
					marginTop: 20,
				}}>
				Traveller Details
			</div>
			<Box
				style={{
					boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
					padding: 20,
				}}>
				{combine.map((i) => {
					return (
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							style={{
								marginTop: 20,
								border: '1.33px solid rgba(203, 203, 203, 1)',
								padding: 20,
								borderRadius: 5,
							}}>
							<Box>
								<Box display={'flex'} alignItems={'center'}>
									<svg
										focusable='false'
										color='inherit'
										fill='none'
										aria-hidden='true'
										role='presentation'
										viewBox='0 0 20 21'
										preserveAspectRatio='xMidYMid meet'
										width='24px'
										height='24px'
										class='sc-hMqMXs llihTZ'>
										<path
											d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
											fill='#319E37'></path>
									</svg>
									<span style={{ marginLeft: 10 }}>
										{i.passengerName.title}
									</span>
									<span
										style={{
											marginLeft: 5,
										}}>{`${i.passengerName.firstname}`}</span>
									<span style={{ marginLeft: 5 }}>
										{i.passengerName.lastname}
									</span>
									<span style={{ marginLeft: 5 }}>{`Seat No. ${i.name}`}</span>
								</Box>
							</Box>
							<span style={{ marginLeft: 10 }}>
								Adult({i.passengerName.gender})
							</span>
						</Box>
					);
				})}

				<Divider />
				<Box
					mt={2}
					style={{
						boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
						padding: 20,
					}}>
					<h2>Select Payment Method</h2>
					<FormControl
						style={{ marginTop: 10 }}
						onChange={(e) => setPay(e.target.value)}>
						<RadioGroup
							row
							aria-labelledby='demo-row-radio-buttons-group-label'
							name='row-radio-buttons-group'>
							{agentInfo?.status === 200 ? (
								<FormControlLabel
									value='OFFLINE'
									disabled={
										Number(Math.round(walletagent?.result?.balanceAmount)) <
										Number(
											location.state.seat &&
												location.state.seat?.length > 0 &&
												location.state.seat
													?.map((item) => parseInt(item.totalFare))
													.reduce((prev, next) => prev + next)
										)
											? true
											: false
									}
									control={
										<Radio
											sx={{
												color: '#088383',
												'&.Mui-checked': {
													color: '#088383',
												},
											}}
										/>
									}
									label={`OFFINE ₹ ${
										location.state.seat &&
										location.state.seat?.length > 0 &&
										location.state.seat
											?.map((item) => parseInt(item.totalFare))
											.reduce((prev, next) => prev + next)
									}`}
								/>
							) : (
								''
							)}
							{/* <FormControlLabel
								value='ONLINE'
								control={
									<Radio
										sx={{
											color: '#088383',
											'&.Mui-checked': {
												color: '#088383',
											},
										}}
									/>
								}
								label={`ONLINE ₹ ${
									Number(
										location.state.seat &&
											location.state.seat?.length > 0 &&
											location.state.seat
												?.map((item) => parseInt(item.totalFare))
												.reduce((prev, next) => prev + next)
									) + Number(pgCharge)
								}`}
							/> */}
						</RadioGroup>
					</FormControl>
				</Box>
				<Grid container>
					<Grid item xs={3}></Grid>
					<Grid item xs={12} lg={6}>
						<Button
							onClick={handleSubmit}
							style={{
								background: '#088383',
								color: 'white',
								width: '100%',
								marginTop: 30,
								padding: 10,
								borderRadius: 12,
							}}>
							Continue
						</Button>
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
			</Box>
			<PaymentVerifyBus handleBook={handleBook} />
			<PaymentEaseBuzzBus pay={pay} pgCharge={pgCharge} />
		</>
	);
};

export default BusConfirmForm;
